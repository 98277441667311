import { css, Typography, useTheme } from '@mui/material';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { HTMLAttributes, useMemo } from 'react';

import { useMediaQuery } from '~/src/hooks/Common/useMediaQuery';

import { HorizontalScrollableLayout } from '../../components/HorizontalScrollableLayout/HorizontalScrollableLayout';
import { MasonryLayout } from '../../components/MasonryLayout/MasonryLayout';
import { ReviewCard } from '../../components/ReviewCard/ReviewCard';

interface Review {
  author: string;
  content: string;
  thumbnail: string;
}

export type ExploreStoriesSectionProps = HTMLAttributes<HTMLDivElement>

export const MasonryReviewsArticle = (props: ExploreStoriesSectionProps) => {
  const theme = useTheme();
  const laptop = useMediaQuery('laptop');
  const { t } = useTranslation('welcome');

  const reviews = useMemo<Review[]>(() => {
    return (
      [
        {
          author: t('stories.reviews.0.author'),
          content: t('stories.reviews.0.content'),
          thumbnail: require('@/assets/images/review_img_01@3x.png'),
        },
        {
          author: t('stories.reviews.1.author'),
          content: t('stories.reviews.1.content'),
          thumbnail: require('@/assets/images/review_img_02@3x.png'),
        },
        {
          author: t('stories.reviews.2.author'),
          content: t('stories.reviews.2.content'),
          thumbnail: require('@/assets/images/review_img_03@3x.png'),
        },
        {
          author: t('stories.reviews.3.author'),
          content: t('stories.reviews.3.content'),
          thumbnail: require('@/assets/images/review_img_04@3x.png'),
        },
        {
          author: t('stories.reviews.4.author'),
          content: t('stories.reviews.4.content'),
          thumbnail: require('@/assets/images/review_img_05@3x.png'),
        },
        {
          author: t('stories.reviews.5.author'),
          content: t('stories.reviews.5.content'),
          thumbnail: require('@/assets/images/review_img_06@3x.png'),
        },
      ]
    );
  }, [t]);

  return (
    <article
      { ...props }
      className={classNames(
        'ExploreStoriesSection',
        props.className
      )}
      style={props.style}
      css={css`
        background-color: ${theme.app.colors['primary-color-05']};
      `}
    >
      <div
        className="ExploreStoriesSection__Wrapper"
        css={css`
          max-width: 1136px;
          padding: 112px 45px 30px;
          margin: auto;
          ${laptop.down.mq} {
            padding: 60px 0px;
          }
        `}
      >
        <div
          className="ExploreStoriesSection__TitleWrapper"
          css={css`
            margin-bottom: 80px;
            text-align: center;
            ${laptop.down.mq} {
              margin-bottom: 40px;
              padding: 0 20px;
            }
          `}
        >
          <Typography
            className="ExploreStoriesSection__Title"
            variant={laptop.down.is ? 'h3' : 'h2'}
            css={css`
              margin-bottom: 12px;
              color: ${theme.app.colors['neutral-color-01']};
            `}
          >
            { t('welcome:stories.title') }
          </Typography>
          <Typography
            variant={laptop.down.is ? 'h5' : 'h3'}
            css={css`
              color: ${theme.app.colors['neutral-color-01']};
            `}
          >
            { t('welcome:stories.description') }
          </Typography>
        </div>
        {
          laptop.down.is === false ?
            (
              <MasonryLayout<Review>
                css={css`
                  margin-bottom: 40px;
                  ${laptop.down.mq} {
                    padding: 0 20px;
                  }
                `}
                data={reviews}
                render={(review) => {
                  return (
                    <ReviewCard
                      author={review.author}
                      content={review.content}
                      thumbnail={review.thumbnail}
                    />
                  );
                }}
              />
            ) :
            (
              <HorizontalScrollableLayout
                css={css`
                  padding: 0 20px;
                `}
              >
                {
                  reviews.map((review, key) => (
                    <ReviewCard
                      key={key}
                      author={review.author}
                      content={review.content}
                      thumbnail={review.thumbnail}
                      css={css`
                        width: 80%;
                        min-width: 80%;
                        align-self: stretch;
                        &:not(:last-of-type) {
                          margin-right: 16px;
                        }
                      `}
                    />
                  ))
                }
              </HorizontalScrollableLayout>
            )
        }
      </div>
    </article>
  );
};
