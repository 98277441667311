import { css, Typography, useTheme } from '@mui/material';
import classNames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { HTMLAttributes } from 'react';

import BrandImage from '@/assets/images/image_home_explorelogos@3x.png';
import { Button } from '~/src/components/app/Button/v2';
import { Nl2br } from '~/src/components/app/Nl2br/Nlbr';
import { TAXONOMY_MAIN_HOME_CTA_EXPLORE_LOGOS } from '~/src/constants/Taxonomy';
import { AmplitudeTaxonomyClient } from '~/src/helpers/AmplitudeTaxonomyClient';

export type ExploreSectionProps = HTMLAttributes<HTMLDivElement>

export const ExploreSection = (props: ExploreSectionProps) => {
  const { t } = useTranslation('welcome');
  const theme = useTheme();

  const onClickCTA = () => {
    AmplitudeTaxonomyClient.clickCtaMainHome({
      action: TAXONOMY_MAIN_HOME_CTA_EXPLORE_LOGOS,
    });
  };

  return (
    <article
      { ...props }
      className={classNames(
        'ExploreSection',
        props.className
      )}
      css={css`
        padding: 60px 20px;
      `}
    >
      <Image
        className="ExploreSection__Image"
        src={BrandImage.src}
        alt={t('common:explore.small-title')}
        width={280}
        height={274}
        css={css`
          display: block;
          margin: auto;
          width: 100%;
          height: auto;
          margin-bottom: 40px;
        `}
      />
      <Typography
        component={'div'}
        className="ExploreSection__Title"
        variant="h3"
        css={css`
          margin-bottom: 36px;
          text-align: center;
          color: ${theme.app.colors['neutral-color-02']};
        `}
      >
        <Nl2br>
          { t('common:explore.small-title') }
        </Nl2br>
      </Typography>
      <Link
        href={'/templates'}
        css={css`
          text-decoration: none;
        `}
      >
        <Button
          color="gradient"
          size="medium"
          css={css`
            width: 100%;
          `}
          onClick={onClickCTA}
        >
          <Typography
            variant="button5"
          >
            { t('common:explore.cta') }
          </Typography>
        </Button>
      </Link>
    </article>
  );
};
