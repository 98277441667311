import { css, Typography, useTheme } from '@mui/material';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { HTMLAttributes, useCallback } from 'react';

import { Button } from '~/src/components/app/Button/v2';
import { AmplitudeTaxonomyClient } from '~/src/helpers/AmplitudeTaxonomyClient';
import { useMediaQuery } from '~/src/hooks/Common/useMediaQuery';
import { nl2brForReact } from '~/src/utils/common';

import { useRelativePosition } from '../../hooks/useRelativePosition/hook';
import { FloatingLogos } from './components/FloatingLogos';

export type HeroSectionProps = HTMLAttributes<HTMLDivElement>

export const HeroArticle = (props: HeroSectionProps) => {
  const theme = useTheme();
  const router = useRouter();
  const laptop = useMediaQuery('laptop');
  const { t } = useTranslation('welcome');
  const { ref } = useRelativePosition();

  const templatePageURI = '/templates';

  const redirectTemplatesPage = useCallback(() => {
    AmplitudeTaxonomyClient.clickCtaMainHome({
      action: 'get_yours_instantly',
    });
    router.push(templatePageURI);
  }, [router]);

  return (
    <article
      { ...props }
      ref={ref}
      className={classNames(
        'HeroSection',
        props.className
      )}
      css={css`
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        height: 800px;
        background: linear-gradient(204deg, #BAC8FC 0%, #F1DFEF 100%);
        ${theme.breakpoints.down('laptop')} {
          height: auto;
          padding: 60px 20px;
        }
      `}
    >
      <div
        className="HeroSection__Content"
        css={css`
          width: 100%;
          text-align: center;
        `}
      >
        <Typography
          variant="h1"
          css={css`
            margin-bottom: 33px;
            ${theme.breakpoints.down('laptop')} {
              ${theme.app.typography.h3}
            }
          `}
        >
          { nl2brForReact(t('hero.title')) }
        </Typography>
        <Typography
          variant="body3"
          css={css`
            margin-bottom: 45px;
            color: ${theme.app.colors['neutral-color-04']};
          `}
        >
          { nl2brForReact(t('hero.description')) }
        </Typography>

        <Button
          role="link"
          data-href={templatePageURI}
          color="gradient"
          radius={100}
          onClick={redirectTemplatesPage}
        >
          <Typography
            variant="button1"
            css={css`
              ${laptop.down.mq} {
                ${theme.app.typography.button4}
              }
            `}
          >
            {
              t('welcome:hero.get')
            }
          </Typography>
        </Button>


      </div>
      { laptop.down.is ? <></> : <FloatingLogos /> }
    </article>
  );
};
