import { css, styled, Typography } from '@mui/material';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { HTMLAttributes } from 'react';

import { useMediaQuery } from '~/src/hooks/Common/useMediaQuery';
import { nl2brForReact } from '~/src/utils/common';

export type CompareTableProps = HTMLAttributes<HTMLDivElement>

const smallIconSize = 17;
const mediumIconSize = 30;

export const CompareTable = (props: CompareTableProps) => {
  const { t } = useTranslation('welcome');
  const laptop = useMediaQuery('laptop');

  return (
    <div
      { ...props }
      className={classNames(
        'CompareTable',
        props.className
      )}
      css={css`
        display: flex;
      `}
    >
      <TitleRow
        className="CompareTable__TitleRow"
      >
        <TitleTh />
        <TitleTd
          className="CompareTable__TitleDocument CompareTable__TitleDocument--first"
        >
          <Typography
            variant={laptop.down.is ? 'body11' : 'body2'}
          >
            { t('welcome:compare.table.items.professional-designer') }
          </Typography>
        </TitleTd>
        <TitleTd
        >
          <Typography
            variant={laptop.down.is ? 'body11' : 'body2'}
          >
            { t('welcome:compare.table.items.brand-guidance') }
          </Typography>
        </TitleTd>
        <TitleTd
        >
          <Typography
            variant={laptop.down.is ? 'body11' : 'body2'}
          >
            { t('welcome:compare.table.items.ready-in-minutes') }
          </Typography>
        </TitleTd>
        <TitleTd
        >
          <Typography
            variant={laptop.down.is ? 'body11' : 'body2'}
          >
            { t('welcome:compare.table.items.no-upfront-payment') }
          </Typography>
        </TitleTd>
        <TitleTd
        >
          <Typography
            variant={laptop.down.is ? 'body11' : 'body2'}
          >
            { t('welcome:compare.table.items.exclusive-ownership') }
          </Typography>
        </TitleTd>
        <TitleTd
          className="CompareTable__TitleDocument CompareTable__TitleDocument--last"
        >
          <Typography
            variant={laptop.down.is ? 'body11' : 'body2'}
          >
            { t('welcome:compare.table.items.price-range') }
          </Typography>
        </TitleTd>
      </TitleRow>
      <HighlightRow>
        <HighlightTh
        >
          <Typography
            component={'div'}
            variant={laptop.down.is ? 'body8' : 'h5'}
          >
            { nl2brForReact(t('welcome:compare.table.titles.logo-maker-shop')) }
          </Typography>
        </HighlightTh>
        <HighlightTd
        >
          <HighlightPossibleIcon title="Possible" />
        </HighlightTd>
        <HighlightTd
        >
          <HighlightPossibleIcon title="Possible" />
        </HighlightTd>
        <HighlightTd
        >
          <HighlightPossibleIcon title="Possible" />
        </HighlightTd>
        <HighlightTd
        >
          <HighlightPossibleIcon title="Possible" />
        </HighlightTd>
        <HighlightTd
        >
          <Typography
            variant={laptop.down.is ? 'body11' : 'body2'}
          >
            { t('welcome:compare.table.logo-maker-shop.packages') }
          </Typography>
        </HighlightTd>
        <HighlightTd
        >
          <Typography
            variant={laptop.down.is ? 'body11' : 'body2'}
          >
            { t('welcome:compare.table.logo-maker-shop.price-range') }
          </Typography>
        </HighlightTd>
      </HighlightRow>
      <OtherRow>
        <OtherTh
        >
          <Typography
            component={'div'}
            variant={laptop.down.is ? 'body8' : 'body1'}
          >
            { nl2brForReact(t('welcome:compare.table.titles.logo-design-platform')) }
          </Typography>
        </OtherTh>
        <OtherTd
        >
          <ImpossibleIcon title="Impossible" />
        </OtherTd>
        <OtherTd
        >
          <ImpossibleIcon title="Impossible" />
        </OtherTd>
        <OtherTd
        >
          <PossibleIcon title="Possible" />
        </OtherTd>
        <OtherTd
        >
          <PossibleIcon title="Possible" />
        </OtherTd>
        <OtherTd
        >
          <ImpossibleIcon title="Impossible" />
        </OtherTd>
        <OtherTd
        >
          <Typography
            variant={laptop.down.is ? 'body11' : 'body2'}
          >
            { t('welcome:compare.table.logo-design-platform.price-range') }
          </Typography>
        </OtherTd>
      </OtherRow>
      <OtherRow>
        <OtherTh
        >
          <Typography
            component={'div'}
            variant={laptop.down.is ? 'body8' : 'body1'}
          >
            { nl2brForReact(t('welcome:compare.table.titles.freelance-designer')) }
          </Typography>
        </OtherTh>
        <OtherTd
        >
          <PossibleIcon title="Possible" />
        </OtherTd>
        <OtherTd
        >
          <PossibleIcon title="Possible" />
        </OtherTd>
        <OtherTd
        >
          <ImpossibleIcon title="Impossible" />
        </OtherTd>
        <OtherTd
        >
          <ImpossibleIcon title="Impossible" />
        </OtherTd>
        <OtherTd
        >
          <PossibleIcon title="Possible" />
        </OtherTd>
        <OtherTd
        >
          <Typography
            variant={laptop.down.is ? 'body11' : 'body2'}
          >
            { t('welcome:compare.table.freelance-designer.price-range') }
          </Typography>
        </OtherTd>
      </OtherRow>
    </div>
  );
};

const TitleRow = styled('div')`
  display: flex;
  flex-direction: column;
  background-color: transparent;
  flex: 1;
  color: ${(props) => props.theme.app.colors['neutral-color-01']};
  text-align: right;
  & > div:not(:last-of-type) {
    border-bottom: none;
  }
`;

const TitleTh = styled('div')`
  height: 110px;
  ${(props) => css`
    ${props.theme.breakpoints.down('laptop')} {
      height: 64px;
    }
  `}
`;

const TitleTd = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 72px;
  padding: 0 25px;
  background-color: ${(props) => props.theme.app.colors['neutral-color-10']};
  border: 1px solid ${(props) => props.theme.app.colors['neutral-color-07']};
  border-right: none;
  text-align: left;
  ${(props) => css`
    ${props.theme.breakpoints.down('laptop')} {
      height: 52px;
      padding: 0 8px;
    }
  `}
`;

const HighlightRow = styled('div')`
  flex: 1;
  background: ${(props) => props.theme.app.colors['primary-gradient-01']};
  color: ${(props) => props.theme.app.colors['neutral-color-01']};
`;

const HighlightTh = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 110px;
  color: ${(props) => props.theme.app.colors['neutral-color-10']};
  text-align: center;
  padding: 0 14px;
  ${(props) => css`
    ${props.theme.breakpoints.down('laptop')} {
      height: 64px;
      padding: 12px 6px;
    }
  `}
`;

const HighlightTd = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 72px;
  background-color: ${(props) => props.theme.app.colors['neutral-color-10']};
  border-left: 3px solid ${(props) => props.theme.app.colors['primary-color-01']};
  border-right: 3px solid ${(props) => props.theme.app.colors['primary-color-01']};
  border-bottom: 1px solid ${(props) => props.theme.app.colors['neutral-color-07']};
  color: ${(props) => props.theme.app.colors['neutral-color-01']};
  text-align: center;
  &:last-of-type {
    border-bottom: 3px solid ${(props) => props.theme.app.colors['primary-color-01']};
  }
  ${(props) => css`
    ${props.theme.breakpoints.down('laptop')} {
      height: 52px;
    }
  `}
`;

const OtherRow = styled('div')`
  flex: 1;
  color: ${(props) => props.theme.app.colors['neutral-color-06']};
`;

const OtherTh = styled('div')`
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${(props) => props.theme.app.colors['neutral-color-07']};
  color: ${(props) => props.theme.app.colors['neutral-color-04']};
  background-color: ${(props) => props.theme.app.colors['neutral-color-10']};
  text-align: center;
  padding: 0 14px;
  ${(props) => css`
    ${props.theme.breakpoints.down('laptop')} {
      height: 64px;
      padding: 12px 6px;
    }
  `}
`;

const OtherTd = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 72px;
  color: ${(props) => props.theme.app.colors['neutral-color-04']};
  background-color: ${(props) => props.theme.app.colors['neutral-color-10']};
  border-bottom: 1px solid ${(props) => props.theme.app.colors['neutral-color-07']};
  border-right: 1px solid ${(props) => props.theme.app.colors['neutral-color-07']};
  text-align: center;
  ${(props) => css`
    ${props.theme.breakpoints.down('laptop')} {
      height: 52px;
    }
  `}
`;

const IconSizeStyles = styled('div')`
  width: ${mediumIconSize}px;
  height: ${mediumIconSize}px;
  ${(props) => css`
    ${props.theme.breakpoints.down('laptop')} {
      width: ${smallIconSize}px;
      height: ${smallIconSize}px;
    }
  `}
`;

const PossibleIcon = styled(IconSizeStyles)`
  border-radius: 50%;
  border: 3px solid transparent;
  background: linear-gradient(white, white) padding-box, 
    linear-gradient(283.28deg, ${(props) => props.theme.app.colors['neutral-color-04']} 12.92%, ${(props) => props.theme.app.colors['neutral-color-04']} 83.6%) border-box;
  ${(props) => css`
    ${props.theme.breakpoints.down('laptop')} {
      border-width: 1.5px;
    }
  `}
`;

const HighlightPossibleIcon = styled(PossibleIcon)`
  background: linear-gradient(white, white) padding-box, linear-gradient(283.28deg, #FF6525 12.92%, #FF8E25 83.6%) border-box;
`;

const ImpossibleIcon = styled(IconSizeStyles)`
  position: relative;
  &::before, &::after {
    content: "";
    position: absolute;
    width: ${Math.hypot(mediumIconSize, mediumIconSize)}px;
    height: 3px;
    ${(props) => css`
      ${props.theme.breakpoints.down('laptop')} {
        width: ${Math.hypot(smallIconSize, smallIconSize)}px;
        height: 1.5px;
      }
    `}
    background-color: ${(props) => props.theme.app.colors['neutral-color-04']};
  }
  &::before {
    top: 0;
    left: 0;
    transform: rotate(45deg) translate(-1%, -50%);
    transform-origin: top left;
  }
  &::after {
    top: 0;
    right: 0;
    transform: rotate(-45deg) translate(1%, -50%);
    transform-origin: top right;
  }
`;
