import { css, styled, Typography, useTheme } from '@mui/material';
import classNames from 'classnames';
import { Trans, useTranslation } from 'next-i18next';
import { HTMLAttributes } from 'react';

import { useMediaQuery } from '~/src/hooks/Common/useMediaQuery';
import { nl2brForReact } from '~/src/utils/common';

import { Accordion } from '../../components/Accordion/Accordion';

export type QuestionsSectionProps = HTMLAttributes<HTMLDivElement>

export const QuestionsSection = (props: QuestionsSectionProps) => {
  const theme = useTheme();
  const laptop = useMediaQuery('laptop');
  const { t } = useTranslation('common');

  return (
    <section
      { ...props }
      className={classNames(
        'QuestionsSection',
        props.className
      )}
    >
      <article
        className="QuestionsSection__Wrapper"
        css={css`
          max-width: 1136px;
          padding: 120px 45px 230px;
          margin: auto;
          ${laptop.down.mq} {
            padding: 60px 20px;
          }
        `}
      >
        <div
          className="QuestionsSection__TitleContainer"
          css={css`
            margin-bottom: 55px;
          `}
        >
          <Typography
            variant="h3"
            css={css`
              text-align: center;
            `}
          >
            {
              t('common:faq.title')
            }
          </Typography>
        </div>
        <div
          className="QuestionsSection__QuestionsContainer"
        >
          <AccordionContainer
            title={t('common:faq.items.0.title') as string}
            content={nl2brForReact(t('faq.items.0.content'))}
          />
          <AccordionContainer
            title={t('common:faq.items.1.title') as string}
            content={nl2brForReact(t('faq.items.1.content'))}
          />
          <AccordionContainer
            title={t('common:faq.items.2.title') as string}
            content={nl2brForReact(t('faq.items.2.content'))}
          />
          <AccordionContainer
            title={t('common:faq.items.3.title') as string}
            content={nl2brForReact(t('faq.items.3.content'))}
          />
          <AccordionContainer
            title={t('common:faq.items.4.title') as string}
            content={(
              <Trans
                i18nKey={'common:faq.items.4.content'}
                components={{
                  support: (
                    <a
                      href="mailto:support@pixo.co"
                      target="_blank"
                      rel="noreferrer"
                      css={css`
                        color: ${theme.app.colors['neutral-color-06']};
                        font-weight: 600;
                      `}
                    />
                  ),
                  newline: <>
                    <br />
                    <br />
                  </>,
                }}
              />
            )}
          />
        </div>
      </article>
    </section>
  );
};

const AccordionContainer = styled(Accordion)`
  margin-bottom: 14px;
  &:last-of-type {
    margin-bottom: 0;
  }
`;
