import { css, Typography, useTheme } from '@mui/material';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useCallback } from 'react';

import BackgroundImage from '@/assets/images/image_home_explorelogos@3x.png';
import { Nl2br } from '~/src/components/app/Nl2br/Nlbr';
import { TAXONOMY_EVENT_TYPE_CLICK_CTA_LOGO_IDEAS, TAXONOMY_EVENT_TYPE_CLICK_CTA_MAIN_HOME, TAXONOMY_MAIN_HOME_CTA_EXPLORE_LOGOS } from '~/src/constants/Taxonomy';
import { AmplitudeTaxonomyClient } from '~/src/helpers/AmplitudeTaxonomyClient';
import { useMediaQuery } from '~/src/hooks/Common/useMediaQuery';

import { Button } from '../../app/Button/v2';
import { TemplateCTACardProps } from './TemplateCTACard.types';

export const TemplateCTACard = ({
  title,
  exploreButtonLabel,
  exploreLink,
  style,
  taxonomy = TAXONOMY_EVENT_TYPE_CLICK_CTA_MAIN_HOME,
}: TemplateCTACardProps) => {
  const theme = useTheme();
  const router = useRouter();
  const mobile = useMediaQuery('mobile');

  const sendTaxonomy = useCallback(() => {
    // 메인 페이지에서 클릭할 때 발생하는 Taxonomy
    if (taxonomy === TAXONOMY_EVENT_TYPE_CLICK_CTA_MAIN_HOME)
      AmplitudeTaxonomyClient.clickCtaMainHome({
        action: TAXONOMY_MAIN_HOME_CTA_EXPLORE_LOGOS,
      });

    // Logo Ideas에서 클릭할 때 발생하는 Taxonomy
    if (taxonomy === TAXONOMY_EVENT_TYPE_CLICK_CTA_LOGO_IDEAS)
      AmplitudeTaxonomyClient.clickCtaLogoIdeas();
  }, [taxonomy]);

  const redirectTemplatesPage = useCallback(() => {
    const link = exploreLink ?? '/templates';

    sendTaxonomy();
    router.push(link);
  }, [
    router,
    exploreLink,
    sendTaxonomy,
  ]);

  const handleClickCTA = useCallback(() => {
    redirectTemplatesPage();
  }, [
    redirectTemplatesPage,
  ]);

  return (
    <div className="BenefitsSection__ExploreWrapper">
      <div
        className={'ExplorerCard'}
        css={css`
          display: flex;
          align-items: center;
          width: 100%;
          background-color: ${theme.app.colors['neutral-color-10']};
          border-radius: 20px;
          padding: 32px 60px;
          justify-content: space-between;
          ${mobile.only.mq} {
            padding: 32px 24px;
            display: flex;
            flex-direction: column;
            text-align: center;
          }
        `}
        style={style}
      >
        <div className="ExploreCard__Content">
          <div
            className="ExploreCard__TitleWrapper"
            css={css`
            margin-bottom: 36px;
          `}
          >
            <Typography
              component={'div'}
              variant="h3"
              css={css`
                color: ${theme.app.colors['neutral-color-02']};
              `}
            >
              <Nl2br>
                { title }
              </Nl2br>
            </Typography>
          </div>
          <div className="ExploreCard__ButtonWrapper">
            <Button
              size="medium"
              color="gradient"
              onClick={handleClickCTA}
            >
              <Typography
                variant="button5"
              >
                { exploreButtonLabel }
              </Typography>
            </Button>
          </div>
        </div>
        <div className="ExploreCard__Image">
          <Image
            alt={title}
            src={BackgroundImage.src}
            width={200}
            height={196}
            css={css`
              ${mobile.only.mq} {
                width: 100%;
                height: 100%;
                margin-top: 40px;
              }
            `}
          />
        </div>
      </div>
    </div>
  );
};
